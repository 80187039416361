import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import RichTextRender from 'components/RichTextRender';
import { Longform, Statement, Title } from 'components/Typography';
import { TwoColumnsListingModel } from 'models/contentful/twoColumnsListing/types';
import RouterLink from 'next/link';
import { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';

const ContentLongform = styled(Longform)`
  & > p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const ActionTitle = styled(Title)(
  ({ theme: { bp } }) => css`
    text-decoration: 'underline';
    margin-right: 0;

    ${bp.l} {
      margin-right: 16px;
    }
  `
);

const DisappearingGap = styled(Gap)(
  ({ theme: { bp } }) =>
    css`
      ${bp.l} {
        display: none;
      }
    `
);

const TitleColumn = styled(Column)<{ rows: number }>(
  ({ rows }) =>
    css`
      grid-row: 1 / span ${rows};
    `
);

const TwoColumnsListing: FC<Omit<TwoColumnsListingModel, 'blockType'>> = ({
  header,
  content,
}) => {
  const rows = content.reduce((count, item) => (item ? count + 1 : count), 0);
  return (
    <Grid columns={{ xs: 6, l: 12 }}>
      <TitleColumn starts={1} spans={{ xs: 6, m: 3, l: 6 }} rows={rows}>
        <Title variant="1" size={{ xs: 'S', l: 'M' }}>
          {header.title}
        </Title>
        {header.description ? (
          <>
            <Gap size={8} />
            <Statement variant="1" size="2XS">
              {header.description}
            </Statement>
          </>
        ) : null}
        {header.actions.length ? (
          <>
            <Gap size={16} />
            {header.actions.map(({ link, title: linkTitle }, index) => (
              <Fragment key={index}>
                <RouterLink legacyBehavior={true} href={link} passHref>
                  <ActionTitle as="a" href={link} variant="1" size="2XS">
                    {linkTitle}
                  </ActionTitle>
                </RouterLink>
                {index < header.actions.length - 1 ? (
                  <DisappearingGap size={16} />
                ) : null}
              </Fragment>
            ))}
          </>
        ) : null}
        <Gap size={{ xs: 32, m: 0 }} />
      </TitleColumn>

      <Column starts={{ xs: 1, m: 4, l: 7 }} spans={{ xs: 6, m: 3, l: 6 }}>
        <ContentLongform variant="1" size={{ xs: 'XS', xl: 'S' }}>
          <RichTextRender content={content[0]} />
        </ContentLongform>
      </Column>

      {content[1] ? (
        <Column starts={{ xs: 1, m: 4, l: 7 }} spans={{ xs: 6, m: 3, l: 6 }}>
          <Gap size={{ xs: 16, m: 32 }} />
          <ContentLongform variant="1" size={{ xs: 'XS', xl: 'S' }}>
            <RichTextRender content={content[1]} />
          </ContentLongform>
        </Column>
      ) : null}

      {content[2] ? (
        <Column starts={{ xs: 1, m: 4, l: 7 }} spans={{ xs: 6, m: 3, l: 6 }}>
          <Gap size={{ xs: 16, m: 32 }} />
          <ContentLongform variant="1" size={{ xs: 'XS', xl: 'S' }}>
            <RichTextRender content={content[2]} />
          </ContentLongform>
        </Column>
      ) : null}

      {content[3] ? (
        <Column starts={{ xs: 1, m: 4, l: 7 }} spans={{ xs: 6, m: 3, l: 6 }}>
          <Gap size={{ xs: 16, m: 32 }} />
          <ContentLongform variant="1" size={{ xs: 'XS', xl: 'S' }}>
            <RichTextRender content={content[3]} />
          </ContentLongform>
        </Column>
      ) : null}
    </Grid>
  );
};

export default TwoColumnsListing;
