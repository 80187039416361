import CtaBlockLink from 'components/CtaBlockLink';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import { Spans } from 'components/Grid/Grid';
import { getColor } from 'components/Stories/helpers';
import { Display, Title } from 'components/Typography';
import { TitleProps } from 'components/Typography/Title';
import { triggerSelectContent } from 'lib/gtag';
import toResponsiveValue, {
  BreakpointKey,
  ResponsiveValue,
} from 'lib/toResponsiveValue';
import { HeroModel } from 'models/contentful/hero/types';
import { LinkModel } from 'models/contentful/link/types';
import { StorySourceModel } from 'models/contentful/storySource/types';
import RouterLink from 'next/link';
import { FC } from 'react';
import styled, { DefaultTheme, ThemeProps, css } from 'styled-components';

interface ActionTitleProps {
  hasBorder: boolean;
  hasFill: boolean;
}

const generateResponsiveStyles = ({
  hasBorder,
  hasFill,
  textColor: c = 'black',
  theme: { bp, colors },
}: TitleProps & ActionTitleProps & ThemeProps<DefaultTheme>) => {
  const color = toResponsiveValue(c);

  const responsiveStyles = Object.keys(bp).map(size => {
    const breakpoint = size as BreakpointKey;
    const inverseColor = color[breakpoint] === 'black' ? '#fff' : '#000';
    const borderColor = hasBorder ? colors[color[breakpoint]] : undefined;
    const backgroundColor = hasFill ? color[breakpoint] : undefined;
    const textColor = hasFill ? inverseColor : undefined;

    return `
      ${bp[breakpoint]} {
        color: ${textColor};
        border-color: ${borderColor};
        background-color: ${backgroundColor};
      }
    `;
  });

  return responsiveStyles.join('');
};

const ActionTitle = styled(Title)<ActionTitleProps>(props => {
  const { hasBorder, hasFill } = props;

  return css`
    margin-right: 16px;
    border: ${hasBorder ? '2px solid black' : undefined};
    padding: ${hasBorder || hasFill ? '18px' : undefined};
    text-decoration: ${hasBorder || hasFill ? 'none' : 'underline'};

    &:last-of-type {
      margin-right: 0;
    }

    ${generateResponsiveStyles(props)}
  `;
});

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled(Grid)<Pick<HeroModel, 'displayMode'>>(
  ({ displayMode }) => {
    switch (displayMode) {
      case 'overlay':
      case 'overlay bottom':
        return css`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: min(100%, calc(100vh - 40px));
        `;
      default:
        return '';
    }
  }
);

const Content = styled(Column)<
  Pick<HeroModel, 'displayMode'> & Pick<StorySourceModel, 'verticalPosition'>
>(({ displayMode, verticalPosition, theme: { bp } }) => {
  const justifyContent = toResponsiveValue(verticalPosition);
  switch (displayMode) {
    case 'overlay':
      return css`
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding-block: 32px;
        justify-content: ${justifyContent.xs};

        ${bp.s} {
          justify-content: ${justifyContent.s};
        }

        ${bp.m} {
          padding-block: 64px;
          justify-content: ${justifyContent.m};
        }

        ${bp.l} {
          justify-content: ${justifyContent.l};
        }

        ${bp.xl} {
          padding-block: 96px;
          justify-content: ${justifyContent.xl};
        }

        ${bp.xxl} {
          justify-content: ${justifyContent.xxl};
        }
      `;
    case 'overlay bottom':
      return css`
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        padding-block: 32px;

        ${bp.m} {
          padding-block: 64px;
        }

        ${bp.xl} {
          padding-block: 96px;
        }
      `;
    default:
      return css`
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
      `;
  }
});

type ResponsiveHzPos =
  | { [key in BreakpointKey]: 'center' | 'left' | 'right' | null }
  | null;

const getColumnConfig = (horizontalPosition: ResponsiveHzPos) => {
  const spans: ResponsiveValue<Spans> = { xs: 2 };
  const starts: ResponsiveValue<number> = { xs: 1 };

  if (!horizontalPosition) return { spans: undefined, starts: undefined };

  for (const bp of Object.keys(horizontalPosition)) {
    const breakpoint = bp as BreakpointKey;
    const pos = horizontalPosition[breakpoint];
    if (pos) {
      if (['left', 'right'].includes(pos)) {
        spans[breakpoint] = 1;
      }
      if (pos === 'right') {
        starts[breakpoint] = -1;
      } else {
        starts[breakpoint] = 1;
      }
    }
  }

  return { spans, starts };
};

type HeroTitleProps = Omit<StorySourceModel, 'media' | 'blockType'> &
  Pick<HeroModel, 'displayMode' | 'ctaStyle'>;

const HeroTitle: FC<HeroTitleProps> = ({
  header,
  textColor,
  horizontalPosition,
  verticalPosition,
  displayMode,
  link,
  ctaStyle,
}) => {
  const { title, description, actions } = header;

  const columnConfig = getColumnConfig(horizontalPosition);

  const handleOnClick = (action: LinkModel) =>
    triggerSelectContent('Hero', title, action, global.window);

  return (
    <Container displayMode={displayMode} columns={2}>
      <Content
        displayMode={displayMode}
        spans={columnConfig.spans}
        starts={columnConfig.starts}
        verticalPosition={verticalPosition}
      >
        {displayMode === 'stack reverse' ? <Gap size={32} /> : null}
        <Display
          as="h1"
          variant="1"
          size={{ xs: '2XS', m: 'XS', l: 'S', xl: 'M' }}
          textColor={getColor({ textColor })}
          textAlign="center"
        >
          <CtaBlockLink
            url={link}
            inline
            onClickCallback={() => handleOnClick({ link, title })}
          >
            {title}
          </CtaBlockLink>
        </Display>
        {description ? (
          <>
            <Gap size={8} />
            <Title
              variant="1"
              as="p"
              size={{ xs: '2XS', l: 'XS', xxl: 'S' }}
              textColor={getColor({ textColor })}
              textAlign="center"
            >
              {description}
            </Title>
          </>
        ) : null}
        {actions.length ? (
          <>
            <Gap size={16} />
            <Actions>
              {actions.map((action, index) => (
                <RouterLink
                  legacyBehavior={true}
                  key={index}
                  href={action.link}
                  passHref
                >
                  <ActionTitle
                    as="a"
                    href={action.link}
                    variant="3"
                    size="2XS"
                    textColor={getColor({ textColor })}
                    hasBorder={ctaStyle === 'border'}
                    hasFill={ctaStyle === 'fill'}
                    onClick={() => handleOnClick(action)}
                  >
                    {action.title}
                  </ActionTitle>
                </RouterLink>
              ))}
            </Actions>
          </>
        ) : null}
        {displayMode === 'stack' ? <Gap size={32} /> : null}
      </Content>
    </Container>
  );
};

export default HeroTitle;
