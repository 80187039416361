import * as t from 'io-ts';
import { heroModel } from 'models/contentful/hero/types';

export const splitHeroModel = t.exact(
  t.interface({
    title: t.string,
    slots: t.array(heroModel),
    blockType: t.literal('SplitHero'),
    delaySeconds: t.number,
  }),
  'SplitHero'
);

export type SplitHeroModel = t.TypeOf<typeof splitHeroModel>;
