import { getColor } from 'components/Stories/helpers';
import { Display } from 'components/Typography';
import { TypographySize } from 'components/Typography/types';
import toResponsiveValue, { ResponsiveValue } from 'lib/toResponsiveValue';
import { StoriesModel } from 'models/contentful/stories/types';
import {
  ResponsiveHorizontalPositionModel,
  ResponsiveVerticalPositionModel,
  StorySourceModel,
} from 'models/contentful/storySource/types';
import RouterLink from 'next/link';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

const LinkedContainer = styled(Container)`
  text-decoration: none;
`;

interface DivProps {
  horizontalPosition: ResponsiveHorizontalPositionModel | null;
  verticalPosition: ResponsiveVerticalPositionModel | null;
}

const Content = styled.div<DivProps>(
  ({ horizontalPosition, verticalPosition, theme: { bp } }) => {
    const textAlign = toResponsiveValue(horizontalPosition);
    const justifyContent = toResponsiveValue(verticalPosition);

    return css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      text-align: ${textAlign.xs};
      justify-content: ${justifyContent.xs};

      ${bp.s} {
        text-align: ${textAlign.s};
        justify-content: ${justifyContent.s};
      }

      ${bp.m} {
        text-align: ${textAlign.m};
        justify-content: ${justifyContent.m};
      }

      ${bp.l} {
        text-align: ${textAlign.l};
        justify-content: ${justifyContent.l};
      }

      ${bp.xl} {
        text-align: ${textAlign.xl};
        justify-content: ${justifyContent.xl};
      }

      ${bp.xxl} {
        text-align: ${textAlign.xxl};
        justify-content: ${justifyContent.xxl};
      }

      h3 {
        margin: 24px;
      }
    `;
  }
);
interface StoriesOverlayTitleWrapperProps {
  link: string;
  children: ReactNode;
}

const StoriesOverlayTitleWrapper = ({
  link,
  children,
}: StoriesOverlayTitleWrapperProps) => {
  return link ? (
    <RouterLink legacyBehavior={true} href={link} passHref={true}>
      <LinkedContainer as="a" href={link}>
        {children}
      </LinkedContainer>
    </RouterLink>
  ) : (
    <Container>{children}</Container>
  );
};

type GetDisplaySizes = (
  args: Pick<StoriesModel, 'columnWidth'>
) => ResponsiveValue<Exclude<TypographySize, 'XL'>>;

const getDisplaySizes: GetDisplaySizes = ({ columnWidth }) => {
  switch (columnWidth) {
    case 4: {
      return { xs: '2XS', xxl: 'XS' };
    }
    case 3:
      return { xs: '2XS', xl: 'XS', xxl: 'S' };

    case 2:
      return { xs: '2XS', l: 'XS', xl: 'S', xxl: 'M' };

    case 1:
      return { xs: '2XS', m: 'XS', l: 'S', xl: 'M', xxl: 'L' };
  }
};

type StoriesOverlayTitleProps = Pick<StoriesModel, 'columnWidth'> &
  Omit<StorySourceModel, 'media' | 'blockType'>;

const StoriesOverlayTitle: FC<StoriesOverlayTitleProps> = ({
  columnWidth,
  header,
  textColor,
  link,
  horizontalPosition,
  verticalPosition,
}) => {
  const { title } = header;

  return (
    <StoriesOverlayTitleWrapper link={link}>
      <Content
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
      >
        <Display
          as="h3"
          variant="1"
          size={getDisplaySizes({ columnWidth })}
          textColor={getColor({ textColor })}
        >
          {title}
        </Display>
      </Content>
    </StoriesOverlayTitleWrapper>
  );
};

export default StoriesOverlayTitle;
