import { Grid } from 'components/Grid';
import ProductCard from 'components/ProductCard';
import { triggerSelectContent } from 'lib/gtag';
import { ProductListingModel } from 'models/productListing/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface ProductGridProps {
  gridWidth: number;
  products: ProductListingModel[];
}

const WrapperGrid = styled(Grid)<Pick<ProductGridProps, 'gridWidth'>>(
  ({ gridWidth, theme: { bp } }) => css`
    display: grid;
    grid-area: list;
    grid: auto / repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 32px;

    ${bp.s} {
      grid-column-gap: 18px;
    }

    ${bp.m} {
      grid: auto / repeat(${gridWidth}, 1fr);
    }

    ${bp.l} {
      grid-column-gap: 24px;
      grid-row-gap: 48px;
    }
  `
);

const ProductGrid: FC<ProductGridProps> = ({ gridWidth, products }) => {
  return (
    <WrapperGrid gridWidth={gridWidth}>
      {products.map((product, index) => (
        <div
          key={product.key + index}
          onClick={() =>
            triggerSelectContent(
              'Product Grid Item',
              product.name,
              { link: product.url, title: 'Product card' },
              global.window
            )
          }
        >
          <ProductCard
            url={product.url}
            name={product.name}
            swatches={product.swatches}
            images={product.images}
            price={product.price}
            tag={product.tag}
            index={index}
          />
        </div>
      ))}
    </WrapperGrid>
  );
};

export default ProductGrid;
