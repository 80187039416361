import { Chevron } from 'components/Icons';
import ProductCard from 'components/ProductCard';
import { Description } from 'components/ProductCard/ProductCard';
import {
  ScrollPosition,
  markScrollPositionCallback,
  scrollLeft,
  scrollRight,
} from 'components/ProductRail/helpers';
import SkeletonCard from 'components/SkeletonCard';
import { triggerSelectContent } from 'lib/gtag';
import { ProductListingModel } from 'models/productListing/types';
import { FC, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

interface ProductRailProps {
  title?: string | null;
  products: ProductListingModel[];
  className?: string;
  loading?: boolean;
  skeletonCount?: number;
}

const Controls = styled.nav(
  ({ theme: { bp } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    height: calc(45vw / 4 * 5);
    transition: opacity 300ms;
    opacity: 0;

    @media (hover: none) {
      display: none;
    }

    > * {
      pointer-events: initial;
    }

    &[data-gt1='false'] {
      display: none;
    }

    ${bp.s} {
      height: calc(45vw / 4 * 5);
    }

    ${bp.m} {
      height: calc(31vw / 4 * 5);

      &[data-gt3='false'] {
        display: none;
      }
    }

    ${bp.l} {
      height: calc(23vw / 4 * 5);

      &[data-gt4='false'] {
        display: none;
      }
    }
  `
);

const Container = styled.div(
  ({ theme: { colors } }) => css`
    background-color: ${colors.white};

    @media (hover: hover) {
      &:hover ${Controls} {
        opacity: 1;
      }
    }
  `
);

const Wrapper = styled.div`
  position: relative;
`;

const Scroller = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: hidden;
  overflow-x: scroll;

  @media (hover: none) {
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
`;

const ScrollerWithHiddenScrollbar = styled(Scroller)({
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
});

const CardContainer = styled.li(
  ({ theme: { bp } }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    width: 45vw;

    @media (hover: none) {
      scroll-snap-align: center;
    }
    ${Description} {
      padding-right: 15px;
    }

    &:first-of-type {
      ${Description} {
        padding-left: 15px;
      }
    }

    ${bp.s} {
      width: 45vw;

      &:first-of-type {
        ${Description} {
          padding-left: 18px;
        }
      }
    }

    ${bp.m} {
      @media (hover: none) {
        scroll-snap-align: start;
      }

      &:first-of-type {
        ${Description} {
          padding-left: 18px;
        }
      }

      width: 31vw;
    }

    ${bp.l} {
      width: 23vw;

      &:first-of-type {
        ${Description} {
          padding-left: 38px;
        }
      }
    }

    ${bp.xl} {
      &:first-of-type {
        ${Description} {
          padding-left: 48px;
        }
      }
    }
  `
);

const Direction = styled.span<{ hidden: boolean }>(
  ({ hidden, theme: { bp, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: ${colors.white};
    appearance: none;
    border: 0;
    cursor: pointer;
    opacity: ${hidden ? 0 : 1};
    transition: opacity 300ms;
    pointer-events: ${hidden ? 'none' : 'initial'};

    ${bp.xl} {
      width: 64px;
      height: 64px;
    }
  `
);

const RailSkeletonCard = styled(SkeletonCard)<{ first?: boolean }>(
  ({ first, theme: { bp } }) => css`
    margin-right: 2.5px;

    > div ~ div {
      margin-left: ${first ? '18px' : '0'};

      ${bp.l} {
        margin-left: ${first ? '38px' : '0'};
      }

      ${bp.xl} {
        margin-left: ${first ? '48px' : '0'};
      }
    }
  `
);

const ProductRail: FC<ProductRailProps> = ({
  products,
  className,
  loading,
  skeletonCount = 5,
}) => {
  const scroller = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] =
    useState<ScrollPosition>('beginning');

  const left = useCallback(scrollLeft(scroller), [scroller]);
  const right = useCallback(scrollRight(scroller), [scroller]);
  const markScrollPosition = useCallback(
    markScrollPositionCallback(scrollPosition, setScrollPosition),
    [scrollPosition, setScrollPosition]
  );

  if (products.length === 0 && !loading) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <ScrollerWithHiddenScrollbar
          ref={scroller}
          onScroll={markScrollPosition}
          className={className}
          data-testid={`related-product-carousel${loading ? '-loading' : ''}`}
        >
          {loading
            ? Array(skeletonCount)
                .fill(0)
                .map((_, i) => (
                  <CardContainer key={i}>
                    <RailSkeletonCard first={i === 0} />
                  </CardContainer>
                ))
            : products.map((product, index) => (
                <CardContainer
                  key={product.key + index}
                  onClick={() =>
                    triggerSelectContent(
                      'Product Carousel Item',
                      product.name,
                      { link: product.url, title: 'Product card' },
                      global.window
                    )
                  }
                >
                  <ProductCard
                    name={product.name}
                    url={product.url}
                    swatches={product.swatches}
                    images={product.images}
                    price={product.price}
                    tag={product.tag}
                    isPreorder={product.preorder}
                  />
                </CardContainer>
              ))}
        </ScrollerWithHiddenScrollbar>
        <Controls
          data-gt4={products.length > 4}
          data-gt3={products.length > 3}
          data-gt1={products.length > 1}
        >
          <Direction
            role="button"
            title="Scroll Left"
            onClick={left}
            hidden={loading === true || scrollPosition === 'beginning'}
          >
            <Chevron direction="left" />
          </Direction>
          <Direction
            role="button"
            title="Scroll Right"
            onClick={right}
            hidden={loading === true || scrollPosition === 'end'}
          >
            <Chevron direction="right" />
          </Direction>
        </Controls>
      </Wrapper>
    </Container>
  );
};

export default ProductRail;
