import CtaBlockLink from 'components/CtaBlockLink';
import {
  Column as ColumnComponent,
  Grid as GridComponent,
} from 'components/Grid';
import { Spans } from 'components/Grid/Grid';
import Media from 'components/Media';
import StoriesOverlayTitle from 'components/StoriesOverlay/StoriesOverlayTitle';
import { triggerSelectContent } from 'lib/gtag';
import { ResponsiveValue } from 'lib/toResponsiveValue';
import { LinkModel } from 'models/contentful/link/types';
import {
  StoriesModel,
  StoryColumnWidth,
} from 'models/contentful/stories/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

type GetSpans = (
  columnWidth: StoryColumnWidth
) => ResponsiveValue<Spans> | undefined;

const getSpans: GetSpans = columnWidth => {
  switch (columnWidth) {
    case 4:
      return { xs: 3, l: 6, xl: 3 };
    case 3:
      return { xs: 6, m: 2, l: 4 };
    case 2:
      return { xs: 3, l: 6 };
    case 1:
      return { xs: 6, l: 12 };
  }
};

const relativeWidths: ResponsiveValue<number>[] = [
  { xs: 1 },
  { xs: 0.5 },
  { xs: 1, m: 0.333333 },
  { xs: 0.5, xl: 0.25 },
];

const Column = styled(ColumnComponent)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Grid = styled(GridComponent)(
  ({ theme: { bp } }) => css`
    margin-bottom: -16px;

    ${bp.m} {
      margin-bottom: -18px;
    }

    ${bp.l} {
      margin-bottom: -24px;
    }
  `
);

const StoriesOverlay: FC<Omit<StoriesModel, 'blockType'>> = ({
  storySource,
  columnWidth,
}) => (
  <Grid spacing="none" inlineMargin={0} columns={{ xs: 6, l: 12 }}>
    {storySource.map((story, index) => {
      const {
        link,
        media,
        header,
        textColor,
        horizontalPosition,
        verticalPosition,
      } = story;

      const spans = getSpans(columnWidth);
      const vwRelativeWidth = relativeWidths[columnWidth - 1];

      const handleOnClick = (title: string, action: LinkModel) =>
        triggerSelectContent('Story CTA - media', title, action, global.window);

      return (
        <Column spans={spans} key={index}>
          <CtaBlockLink
            url={link}
            onClickCallback={() =>
              handleOnClick(media.xs?.image.title || header.title, {
                link,
                title: header.title,
              })
            }
          >
            <Media vwRelativeWidth={vwRelativeWidth} {...media} />
          </CtaBlockLink>
          <StoriesOverlayTitle
            columnWidth={columnWidth}
            header={header}
            textColor={textColor}
            horizontalPosition={horizontalPosition}
            verticalPosition={verticalPosition}
            link={link}
          />
        </Column>
      );
    })}
  </Grid>
);

export default StoriesOverlay;
