import CtaBlockLink from 'components/CtaBlockLink';
import HeroTitle from 'components/Hero/HeroTitle';
import Media from 'components/Media';
import { triggerSelectContent } from 'lib/gtag';
import { HeroModel } from 'models/contentful/hero/types';
import { LinkModel } from 'models/contentful/link/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<Pick<HeroModel, 'displayMode'>>(
  ({ displayMode }) => {
    const heroHeight = `calc(100vh - ${
      displayMode === 'overlay' ? '40px' : '120px'
    })`;

    return css`
      ${displayMode === 'stack' ? `display: flex;` : ''}
      ${displayMode === 'stack' ? `flex-direction: column-reverse;` : ''}
      position: relative;
      display: flex;

      height: ${heroHeight};

      overflow: hidden;

      img {
        object-fit: cover;
        object-position: 50% 20%;
      }
    `;
  }
);

const Hero: FC<Omit<HeroModel, 'blockType'>> = ({
  storySource,
  displayMode,
  ctaStyle,
}) => {
  const {
    media,
    textColor,
    horizontalPosition,
    verticalPosition,
    header,
    link,
    linkLabel,
  } = storySource;

  const handleOnClick = (title: string, action: LinkModel) =>
    triggerSelectContent('Hero - media', title, action, global.window);

  return (
    <Container displayMode={displayMode}>
      <CtaBlockLink
        url={link}
        onClickCallback={() =>
          handleOnClick(media.xs?.image.title || header.title, {
            link,
            title: header.title,
          })
        }
      >
        <Media {...media} alt={linkLabel} />
      </CtaBlockLink>
      <HeroTitle
        displayMode={displayMode}
        header={header}
        textColor={textColor}
        horizontalPosition={horizontalPosition}
        verticalPosition={verticalPosition}
        link={link}
        linkLabel={linkLabel}
        ctaStyle={ctaStyle}
      />
    </Container>
  );
};

export default Hero;
