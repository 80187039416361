import CtaBlockLink from 'components/CtaBlockLink';
import HeroHalfTitle from 'components/HeroHalf/HeroHalfTitle';
import Media from 'components/Media';
import { triggerSelectContent } from 'lib/gtag';
import { HeroHalfModel } from 'models/contentful/heroHalf/types';
import { LinkModel } from 'models/contentful/link/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<Pick<HeroHalfModel, 'displayMode'>>(
  ({ displayMode, theme: { bp } }) => {
    const heroHeight = `calc(100vh - 80px)`;

    return css`
      display: flex;
      flex-direction: ${displayMode === 'standard'
        ? 'column'
        : 'column-reverse'};
      ${bp.m} {
        flex-direction: ${displayMode === 'standard' ? 'row' : 'row-reverse'};
      }

      height: ${heroHeight};
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: 50% 20%;
      }
    `;
  }
);

const HeroHalf: FC<Omit<HeroHalfModel, 'blockType'>> = ({
  storySource,
  displayMode,
  backgroundColor,
}) => {
  const {
    media,
    textColor,
    horizontalPosition,
    verticalPosition,
    header,
    link,
  } = storySource;

  const handleOnClick = (title: string, action: LinkModel) =>
    triggerSelectContent('Half Hero - media', title, action, global.window);

  return (
    <Container displayMode={displayMode}>
      <CtaBlockLink
        url={link}
        onClickCallback={() =>
          handleOnClick(media.xs?.image.title || header.title, {
            link,
            title: header.title,
          })
        }
      >
        <Media vwRelativeWidth={{ xs: 1, m: 0.5 }} {...media} />
      </CtaBlockLink>
      <HeroHalfTitle
        header={header}
        textColor={textColor}
        horizontalPosition={horizontalPosition}
        verticalPosition={verticalPosition}
        backgroundColor={backgroundColor}
        link={link}
      />
    </Container>
  );
};

export default HeroHalf;
