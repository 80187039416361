import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

interface SkeletonCardProps {
  className?: string;
}

const pulse = keyframes`
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;

const Figure = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 5;
    overflow: hidden;
    margin: 0;
    background-color: ${colors.grey2};
    animation: ${pulse} 3s linear infinite;
  `
);

const CardWrapper = styled.div`
  display: block;
  position: relative;
  height: 100%;
`;

const Name = styled.span(
  ({ theme: { colors } }) => css`
    display: block;
    height: 16px;
    margin-bottom: 20px;
    margin-top: 16px;
    background-color: ${colors.grey2};
    animation: ${pulse} 3s linear infinite;
  `
);

const Tag = styled.span(
  ({ theme: { colors } }) => css`
    margin-top: 4px;
    display: block;
    width: 120px;
    height: 13px;
    background-color: ${colors.grey2};
    animation: ${pulse} 3s linear infinite;
  `
);

const Price = styled.span(
  ({ theme: { colors } }) => css`
    display: block;
    margin-top: 2px;
    height: 12px;
    width: 30px;
    background-color: ${colors.grey2};
    animation: ${pulse} 3s linear infinite;
  `
);

export const SkeletonCard: FC<SkeletonCardProps> = ({ className }) => (
  <CardWrapper className={className}>
    <Figure />
    <Name />
    <Tag />
    <Price />
  </CardWrapper>
);

export default SkeletonCard;
