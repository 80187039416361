import * as t from 'io-ts';
import { storySourceModel } from 'models/contentful/storySource/types';

export const heroHalfModel = t.exact(
  t.interface({
    storySource: storySourceModel,
    displayMode: t.keyof({
      standard: null,
      reverse: null,
    }),
    backgroundColor: t.string,
    blockType: t.literal('HeroHalf'),
  }),
  'HeroHalf'
);

export type HeroHalfModel = t.TypeOf<typeof heroHalfModel>;
