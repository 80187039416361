import * as t from 'io-ts';
import { productListingModel } from 'models/productListing/types';

export const productRailModel = t.exact(
  t.interface({
    title: t.string,
    products: t.array(productListingModel),
    blockType: t.literal('ProductRail'),
  }),
  'ProductRail'
);

export type ProductRailModel = t.TypeOf<typeof productRailModel>;
