import * as t from 'io-ts';

export const dashHudsonUGCModel = t.type(
  {
    galleryId: t.string,
    rowSize: t.string,
    gapSize: t.string,
    rowLimit: t.string,
    mobileRowSize: t.string,
    mobileGapSize: t.string,
    blockType: t.literal('DashHudsonUGC'),
  },
  'DashHudsonUGC'
);

export type DashHudsonUGCModel = t.TypeOf<typeof dashHudsonUGCModel>;
