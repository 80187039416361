import Gap from 'components/Gap';
import ProductRail from 'components/ProductRail';
import Story from 'components/Story';
import { OutfitModel } from 'models/contentful/outfit/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  ({ theme: { bp } }) => css`
    display: flex;
    flex-direction: column;
    width: 100;

    ${bp.m} {
      flex-direction: row;
    }
  `
);

const StoryContainer = styled.div(
  ({ theme: { bp } }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 15px;
    margin-right: 15px;

    ${bp.s} {
      margin-left: 18px;
      margin-right: 18px;
    }

    ${bp.m} {
      width: 30.2083vw;
      margin-left: 18px;
      margin-right: 18px;
    }

    ${bp.l} {
      width: 21.3867vw;
      margin-left: 38px;
      margin-right: 24px;
    }

    ${bp.xl} {
      width: 22.0833vw;
      margin-left: 48px;
    }

    ${bp.xxl} {
      width: 22.8125vw;
    }
  `
);

const ProductRailContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
`;

const Rail = styled(ProductRail)(
  ({ theme: { bp } }) => css`
    ${bp.m} {
      > *:first-of-type {
        padding-left: 0;
      }
    }
  `
);

const Outfit: FC<Omit<OutfitModel, 'blockType'>> = ({
  storySource,
  products,
}) => (
  <Wrapper>
    <StoryContainer>
      <Story vwRelativeWidth={{ xs: 1, m: 0.3333, l: 0.25 }} {...storySource} />
      <Gap size={{ xs: 32, m: 0 }} />
    </StoryContainer>
    <ProductRailContainer>
      <Rail title="" products={products} />
    </ProductRailContainer>
  </Wrapper>
);

export default Outfit;
