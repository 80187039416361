import { type Document } from '@contentful/rich-text-types';
import * as t from 'io-ts';
import { headerModel } from 'models/contentful/header/types';
import { document } from 'models/contentful/richText/types';

export const twoColumnsListingModel = t.exact(
  t.interface({
    header: headerModel,
    content: t.tuple([
      document,
      t.union([document, t.null]),
      t.union([document, t.null]),
      t.union([document, t.null]),
    ]),
    blockType: t.literal('TwoColumnsListing'),
  }),
  'TwoColumnsListing'
);

export interface TwoColumnsListingModel
  extends Omit<t.TypeOf<typeof twoColumnsListingModel>, 'content'> {
  content: [Document, Document | null, Document | null, Document | null];
}
