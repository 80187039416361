import * as t from 'io-ts';
import { document } from 'models/contentful/richText/types';
import { sizeGuideTabModel } from 'models/contentful/sizeGuideTab/types';

export const sizeGuideTabsModel = t.exact(
  t.interface({
    introductionSection: document,
    tabs: t.array(sizeGuideTabModel),
    blockType: t.literal('SizeGuideTabs'),
  }),
  'SizeGuideTabs'
);

export type SizeGuideTabsModel = t.TypeOf<typeof sizeGuideTabsModel>;
