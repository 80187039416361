import * as t from 'io-ts';

export const grapheneHcRecommendationsCarouselModel = t.exact(
  t.interface({
    pageElementId: t.string,
    blockType: t.literal('GrapheneHcRecommendationsCarousel'),
  }),
  'GrapheneHcRecommendationsCarousel'
);

export type GrapheneHcRecommendationsCarouselModel = t.TypeOf<
  typeof grapheneHcRecommendationsCarouselModel
>;
