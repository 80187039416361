import { DashHudsonUGCModel } from 'models/contentful/dashHudsonUGC/types';
import { FC, useEffect, useId } from 'react';

const loadScript = (
  targetId: string,
  props: DashHudsonUGCModel & {
    src: string;
    name: string;
    hidePopupFooter: string;
  }
) => {
  return new Promise((resolve, reject) => {
    const alreadyLoaded = document
      ?.getElementById(targetId)
      ?.querySelector('.dh-widget-container');
    if (alreadyLoaded) {
      return;
    }
    const script = document.createElement('script');
    script.src = props.src;
    script.async = true;
    Object.entries(props).forEach(([key, value]) => {
      script.dataset[key] = value;
    });
    script.onload = resolve;
    script.onerror = reject;
    document?.getElementById(targetId)?.appendChild(script);
  });
};

const DashHudsonUGC: FC<DashHudsonUGCModel> = props => {
  const id = useId();

  useEffect(() => {
    loadScript(id, {
      ...props,
      name: 'dhboard',
      src: 'https://cdn.dashhudson.com/web/js/board-embed.js',
      hidePopupFooter: 'true',
    });
  });

  return <div id={id} key={id}></div>;
};

export default DashHudsonUGC;
