import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import { Special } from 'components/Typography';
import useRecommendations from 'hooks/useRecommendations';
import { GrapheneHcRecommendationsGridModel } from 'models/contentful/grapheneHcRecommendationsGrid/types';
import dynamic from 'next/dynamic';
import { FC } from 'react';

const ProductList = dynamic(() => import('components/ProductList'), {
  ssr: false,
});

const RecommendationsGrid: FC<
  Omit<GrapheneHcRecommendationsGridModel, 'blockType'>
> = ({ pageElementId: element, openQuickView, title }) => {
  const { loading, recommendations } = useRecommendations({ element });

  return (
    <>
      {title ? (
        <Grid columns={1}>
          <Column>
            <Special variant="1" size="L">
              {title}
            </Special>
            <Gap size={16} />
          </Column>
        </Grid>
      ) : null}
      <ProductList
        loading={loading}
        products={recommendations.map(({ details, listing }) => ({
          details,
          listing: { ...listing, swatches: {} },
        }))}
        openQuickView={openQuickView}
      />
    </>
  );
};

export default RecommendationsGrid;
