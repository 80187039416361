import * as t from 'io-ts';
import { productListingModel } from 'models/productListing/types';

export const productGridModel = t.exact(
  t.interface({
    gridWidth: t.number,
    products: t.array(productListingModel),
    blockType: t.literal('ProductGrid'),
  }),
  'ProductGrid'
);

export type ProductGridModel = t.TypeOf<typeof productGridModel>;
