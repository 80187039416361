import { Document } from '@contentful/rich-text-types';
import CtaBlockLink from 'components/CtaBlockLink';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import Media from 'components/Media';
import RichTextRender from 'components/RichTextRender';
import { Statement } from 'components/Typography';
import { ResponsiveValue } from 'lib/toResponsiveValue';
import { PictureModel } from 'models/contentful/picture/types';
import { FC } from 'react';
import styled from 'styled-components';

const startColumn: Record<PictureModel['size'], ResponsiveValue<number>> = {
  Small: { xs: 1, m: 2, l: 4 },
  Medium: { xs: 1, m: 2, l: 3 },
  Large: { xs: 1 },
  Full: { xs: 1 },
};

const endColumn: Record<PictureModel['size'], ResponsiveValue<number>> = {
  Small: { xs: 7, m: 6, l: 10 },
  Medium: { xs: 7, m: 6, l: 11 },
  Large: { xs: 7, l: 13 },
  Full: { xs: 7, l: 13 },
};

const relativeWidths: Record<PictureModel['size'], ResponsiveValue<number>> = {
  Small: { xs: 1, m: 0.666, l: 0.5 },
  Medium: { xs: 1, m: 0.666 },
  Large: { xs: 1 },
  Full: { xs: 1 },
};

const MediaGrid = styled(Grid)`
  line-height: 1px;
`;

const CenteredStatement = styled(Statement)`
  text-align: center;
`;

const Picture: FC<Omit<PictureModel, 'blockType'>> = ({
  caption,
  picture,
  link,
  size,
}) => {
  return (
    <>
      <MediaGrid
        inlineMargin={size === 'Full' ? 0 : undefined}
        columns={{ xs: 6, l: 12 }}
      >
        <Column starts={startColumn[size]} ends={endColumn[size]}>
          <CtaBlockLink url={link}>
            <Media vwRelativeWidth={relativeWidths[size]} {...picture} />
          </CtaBlockLink>
        </Column>
      </MediaGrid>
      {caption ? (
        <>
          <Gap size={16} />
          <Grid columns={{ xs: 6, l: 12 }}>
            <Column starts={startColumn[size]} ends={endColumn[size]}>
              <CenteredStatement as="div" size="2XS" variant="1">
                <RichTextRender content={caption as Document} />
              </CenteredStatement>
            </Column>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default Picture;
