import CtaBlockLink from 'components/CtaBlockLink';
import Gap from 'components/Gap';
import { Column, Grid as GridComponent } from 'components/Grid';
import { getColor } from 'components/Stories/helpers';
import { Display, Statement, Title } from 'components/Typography';
import { triggerSelectContent } from 'lib/gtag';
import { HeroHalfModel } from 'models/contentful/heroHalf/types';
import { LinkModel } from 'models/contentful/link/types';
import { StorySourceModel } from 'models/contentful/storySource/types';
import RouterLink from 'next/link';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const ActionTitle = styled(Title)(
  ({ textColor }) => css`
    color: ${textColor};
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  `
);

const Actions = styled.div(
  ({ theme: { bp } }) => css`
    display: flex;
    justify-content: flex-start;

    ${bp.l} {
      justify-content: center;
    }
  `
);

const Container = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => css`
    flex: 1;
    display: flex;
    background-color: ${backgroundColor};
    flex-direction: column;
    justify-content: center;
  `
);

const Grid = styled(GridComponent)(
  ({ theme: { bpRange } }) => css`
    @media screen and (${bpRange.m}) {
      margin-left: 16px;
      margin-right: 16px;
    }
  `
);

type HeroHalfTitleProps = Omit<StorySourceModel, 'media' | 'blockType'> &
  Pick<HeroHalfModel, 'backgroundColor'>;

const HeroHalfTitle: FC<HeroHalfTitleProps> = ({
  header,
  textColor,
  backgroundColor,
  link,
}) => {
  const { title, description, actions } = header;

  const handleOnClick = (action: LinkModel) =>
    triggerSelectContent('Half Hero', title, action, global.window);

  return (
    <Container backgroundColor={backgroundColor}>
      <Grid columns={{ xs: 6, l: 4, xl: 6 }}>
        <Column spans={{ xs: 6, l: 4 }} starts={{ xs: 1, xl: 2 }}>
          <Gap size={{ xs: 32, m: 0 }} />
          <Display
            as="h1"
            variant="1"
            size={{ xs: '2XS', l: 'XS', xl: 'S' }}
            textColor={getColor({ textColor })}
            textAlign={{ xs: 'left', l: 'center' }}
          >
            <CtaBlockLink
              onClickCallback={() => handleOnClick({ link, title: title })}
              url={link}
              inline
            >
              {title}
            </CtaBlockLink>
          </Display>
          {description ? (
            <>
              <Gap size={16} />
              <Statement
                variant="1"
                as="p"
                size={{ xs: 'XS' }}
                textColor={getColor({ textColor })}
                textAlign={{ xs: 'left', l: 'center' }}
              >
                {description}
              </Statement>
            </>
          ) : null}
          {actions.length ? (
            <>
              <Gap size={16} />
              <Actions>
                {actions.map((action, index) => (
                  <RouterLink
                    legacyBehavior={true}
                    key={index}
                    href={action.link}
                    passHref
                  >
                    <ActionTitle
                      as="a"
                      variant="1"
                      size="2XS"
                      textColor={getColor({ textColor })}
                      onClick={() => handleOnClick(action)}
                    >
                      {action.title}
                    </ActionTitle>
                  </RouterLink>
                ))}
              </Actions>
            </>
          ) : null}
          <Gap size={{ xs: 32, m: 0 }} />
        </Column>
      </Grid>
    </Container>
  );
};

export default HeroHalfTitle;
