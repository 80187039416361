import Hero from 'components/Hero/Hero';
import HeroHalf from 'components/HeroHalf';
import { ChevronSmall, Pause, Play } from 'components/Icons';
import { useSwipe } from 'lib/react';
import { ContentPageModel } from 'models/contentPages/types';
import { HeroCarouselModel } from 'models/contentful/heroCarousel/types';
import { FC, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const CarouselContainer = styled.div<
  { transform: number; animate: boolean } & Pick<
    ContentPageModel,
    'displayMode'
  >
>(({ transform, displayMode, animate, theme: { bp } }) => {
  const carouselHeight = `calc(100vh - ${
    displayMode === 'overlay' ? '40px' : '120px'
  })`;
  return css`
    display: flex;
    box-sizing: content-box;
    transform: translateX(-${transform * 100}%);
    ${animate &&
    css`
      transition: transform 0.5s ease-out;
    `}

    .ItemWrapper_Hero > div {
      max-height: ${carouselHeight};
      height: ${carouselHeight};
      display: flex;
      flex-direction: column;

      > div:nth-child(1) {
        overflow: auto;
      }

      > div:nth-child(2) {
        padding-bottom: 32px;
      }
    }

    .ItemWrapper_HeroHalf {
      > div {
        max-height: ${carouselHeight};
        height: ${carouselHeight};

        ${displayMode === 'overlay' &&
        css`
          > div:nth-child(2) {
            padding-top: 32px;
            padding-bottom: 32px;
          }
        `}

        > div:first-child, 
        > a {
          overflow: auto;
          flex: 2.5;

          ${bp.m} {
            flex: 1;
          }
        }
      }
    }

    img {
      object-fit: cover;
      object-position: 50% 20%;
    }
  `;
});

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ItemWrapper = styled.div`
  min-width: 320px;
  box-sizing: content-box;
  flex: 0 0 100%;
`;

const ButtonStyles = css`
  background: rgb(255, 255, 255, 0.2);
  border: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  z-index: 1;

  &:hover,
  &:focus {
    background: rgb(255, 255, 255, 0.6);
  }
`;

const CarouselArrowLeft = styled.button`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  ${ButtonStyles}
`;

const CarouselArrowRight = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  ${ButtonStyles}
`;

const CarouselPauseButton = styled.button`
  position: absolute;
  left: 8px;
  bottom: 8px;
  ${ButtonStyles}
  > svg {
    transform: scale(0.8);
  }
`;

const CarouselPaginationContainer = styled.div`
  position: absolute;
  bottom: 16px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  gap: 16px;
  z-index: 1;
  height: 6px;
`;

const CarouselPaginationButton = styled.button`
  flex: 1;
  border: none;
  padding: 0;
  background: rgb(167, 167, 167, 0.4);
  height: 4px;
  border-radius: 4px;
  overflow: auto;

  &:hover,
  &:focus {
    height: 6px;
    background: rgb(167, 167, 167, 0.6);
  }
`;

const CarouselPaginationProgress = styled.div<{
  playing: boolean;
  duration: number;
  selected: boolean;
}>(
  ({ playing, duration, selected }) => css`
    border: none;
    padding: 0;
    background: white;
    height: 100%;

    width: ${selected ? '100%' : 0};
    animation-name: ${selected ? 'none' : 'widthAnimation'};

    animation-play-state: ${playing ? 'running' : 'paused'};
    animation-duration: ${duration}ms;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    @keyframes widthAnimation {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  `
);

const HeroCarousel: FC<
  Omit<HeroCarouselModel, 'blockType'> & Pick<ContentPageModel, 'displayMode'>
> = ({ slots, delaySeconds, displayMode }) => {
  const [slide, setSlide] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(0);
  const [playing, setPlaying] = useState(true);
  const delay = delaySeconds * 1000;

  useEffect(() => {
    const slidingTimeout = setInterval(() => {
      playing &&
        setSlide(slide => {
          setPreviousSlide(slide);
          return slide === slots.length - 1 ? 0 : slide + 1;
        });
    }, delay);

    return () => clearInterval(slidingTimeout);
  }, [playing]);

  const children = slots.reduce<ReactNode[]>((acc, slot, index) => {
    switch (slot.blockType) {
      case 'Hero':
        acc.push(
          <ItemWrapper
            key={index}
            className="ItemWrapper_Hero"
            onFocus={e => {
              e.preventDefault();
              handleSlideSelection(index);
            }}
          >
            <Hero {...slot} />
          </ItemWrapper>
        );
        return acc;

      case 'HeroHalf':
        acc.push(
          <ItemWrapper
            key={index}
            className="ItemWrapper_HeroHalf"
            onFocus={e => {
              e.preventDefault();
              handleSlideSelection(index);
            }}
          >
            <HeroHalf {...slot} />
          </ItemWrapper>
        );
        return acc;

      default:
        return acc;
    }
  }, []);

  const handlePreviousClick = () => {
    setPlaying(false);
    setPreviousSlide(slide);
    setSlide(slide === 0 ? slots.length - 1 : slide - 1);
  };
  const handleNextClick = () => {
    setPlaying(false);
    setPreviousSlide(slide);
    setSlide(slide === slots.length - 1 ? 0 : slide + 1);
  };
  const handleSlideSelection = (index: number) => {
    setPlaying(false);
    setPreviousSlide(slide);
    setSlide(index);
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => slide !== slots.length - 1 && handleNextClick(),
    onSwipedRight: () => slide !== 0 && handlePreviousClick(),
  });

  return (
    <Container {...swipeHandlers} data-testid="hero-carousel-container">
      <CarouselArrowLeft
        onClick={handlePreviousClick}
        aria-label="Previous slide"
        data-testid="previous-slide-button"
      >
        <ChevronSmall direction="left" />
      </CarouselArrowLeft>

      <CarouselArrowRight
        onClick={handleNextClick}
        aria-label="Next slide"
        data-testid="next-slide-button"
      >
        <ChevronSmall direction="right" />
      </CarouselArrowRight>

      <CarouselPauseButton
        aria-label={playing ? 'Pause' : 'Play'}
        data-testid="pause-button"
        onClick={() => setPlaying(!playing)}
      >
        {playing ? <Pause /> : <Play />}
      </CarouselPauseButton>

      <CarouselPaginationContainer>
        {Array.from(Array(slots.length)).map((_, index) => (
          <CarouselPaginationButton
            key={`slide-pag-${index}`}
            type="button"
            aria-label={`Show slide ${index + 1}`}
            data-testid={`pagination-button-${index}`}
            onClick={() => handleSlideSelection(index)}
          >
            {index === slide && (
              <CarouselPaginationProgress
                data-testid={`pagination-progress-${index}`}
                duration={delay}
                playing={playing}
                selected={!playing}
              />
            )}
          </CarouselPaginationButton>
        ))}
      </CarouselPaginationContainer>

      <CarouselContainer
        transform={slide}
        animate={Math.abs(slide - previousSlide) === 1}
        displayMode={displayMode}
      >
        {children}
      </CarouselContainer>
    </Container>
  );
};

export default HeroCarousel;
