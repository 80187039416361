import * as t from 'io-ts';
import { storySourceModel } from 'models/contentful/storySource/types';
import { productListingModel } from 'models/productListing/types';

export const outfitModel = t.exact(
  t.interface({
    storySource: storySourceModel,
    products: t.array(productListingModel),
    blockType: t.literal('Outfit'),
  }),
  'Outfit'
);

export type OutfitModel = t.TypeOf<typeof outfitModel>;
