import * as t from 'io-ts';
import { document } from 'models/contentful/richText/types';
import { imageModel } from 'models/contentful/shared/types';
import { sizeGuideTableModel } from 'models/contentful/sizeGuideTable/types';

export const sizeGuideTabModel = t.exact(
  t.interface({
    tabName: t.string,
    howToMeasureImage: t.union([imageModel, t.null]),
    howToMeasureDescription: t.union([document, t.null]),
    tables: t.array(sizeGuideTableModel),
  }),
  'SizeGuideTab'
);

export type SizeGuideTabModel = t.TypeOf<typeof sizeGuideTabModel>;
