import { Column, Grid } from 'components/Grid';
import { Longform } from 'components/Typography';
import { ContributorsModel } from 'models/contentful/contributors/types';
import { FC } from 'react';
import styled from 'styled-components';

const ContributorList = styled.ul`
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Contributors: FC<Omit<ContributorsModel, 'blockType'>> = ({ items }) => (
  <Grid columns={{ xs: 6, l: 12 }}>
    <Column starts={1} spans={{ xs: 6, l: 12 }}>
      <ContributorList>
        {items.map(item => (
          <Longform
            key={item.contribution + item.contributorsName}
            as="li"
            variant="1"
            size="2XS"
          >
            <b>{item.contribution}</b> {item.contributorsName}
          </Longform>
        ))}
      </ContributorList>
    </Column>
  </Grid>
);

export default Contributors;
