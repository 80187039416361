import { Column } from 'components/Grid';
import styled, { css } from 'styled-components';

const InlineStoryColumn = styled(Column)(
  ({ theme: { bp } }) => css`
    picture,
    video {
      height: 100%;

      ${bp.l} {
        height: calc(100% - 113px);
      }
    }

    picture {
      width: 100%;
      aspect-ratio: 4 / 5;
    }

    picture img,
    video {
      object-fit: cover;
    }

    [data-type='CtaBlockLink'] {
      ${bp.l} {
        height: calc(100% - 113px);
      }

      picture,
      video {
        height: 100%;

        ${bp.l} {
          height: 100%;
        }
      }
    }
  `
);

export default InlineStoryColumn;
