import * as t from 'io-ts';

export const grapheneHcRecommendationsGridModel = t.exact(
  t.interface({
    pageElementId: t.string,
    openQuickView: t.boolean,
    title: t.string,
    blockType: t.literal('GrapheneHcRecommendationsGrid'),
  }),
  'GrapheneHcRecommendationsGrid'
);

export type GrapheneHcRecommendationsGridModel = t.TypeOf<
  typeof grapheneHcRecommendationsGridModel
>;
