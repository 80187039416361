import * as t from 'io-ts';

export const contributorsModel = t.exact(
  t.interface({
    items: t.array(
      t.interface({
        contribution: t.string,
        contributorsName: t.string,
      })
    ),
    blockType: t.literal('Contributors'),
  }),
  'Contributors'
);

export type ContributorsModel = t.TypeOf<typeof contributorsModel>;
