import * as t from 'io-ts';
import { storySourceModel } from 'models/contentful/storySource/types';

export const storyColumnWidth = t.union([
  t.literal(1),
  t.literal(2),
  t.literal(3),
  t.literal(4),
]);

export const storiesModel = t.exact(
  t.interface({
    storySource: t.array(storySourceModel),
    columnWidth: storyColumnWidth,
    blockType: t.literal('Stories'),
  }),
  'Stories'
);

export type StoryColumnWidth = t.TypeOf<typeof storyColumnWidth>;
export type StoriesModel = t.TypeOf<typeof storiesModel>;
