import * as t from 'io-ts';

import { storySourceModel } from 'models/contentful/storySource/types';

export const heroModel = t.exact(
  t.interface({
    storySource: storySourceModel,
    displayMode: t.keyof({
      overlay: null,
      'overlay bottom': null,
      stack: null,
      'stack reverse': null,
    }),
    ctaStyle: t.keyof({
      underline: null,
      border: null,
      fill: null,
    }),
    blockType: t.literal('Hero'),
  }),
  'Hero'
);

export type HeroModel = t.TypeOf<typeof heroModel>;
