import * as t from 'io-ts';
import { mediaModel } from 'models/contentful/media/types';
import { storySourceModel } from 'models/contentful/storySource/types';
import { productListingModel } from 'models/productListing/types';

export const productMosaicModel = t.intersection(
  [
    t.interface({
      displayMode: t.keyof({
        Standard: null,
        Reverse: null,
      }),
      mobileDisplayMode: t.keyof({
        Standard: null,
        Reverse: null,
      }),
      products: t.tuple([
        productListingModel,
        productListingModel,
        productListingModel,
        productListingModel,
      ]),
      blockType: t.literal('ProductMosaic'),
    }),
    t.union([
      t.interface({
        media: t.null,
        storySource: storySourceModel,
      }),
      t.interface({
        media: mediaModel,
        storySource: t.null,
      }),
      t.interface({
        media: mediaModel,
        storySource: storySourceModel,
      }),
    ]),
  ],
  'ProductMosaic'
);

export type ProductMosaicModel = t.TypeOf<typeof productMosaicModel>;
