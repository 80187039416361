import * as t from 'io-ts';
import { heroModel } from 'models/contentful/hero/types';
import { heroHalfModel } from 'models/contentful/heroHalf/types';

export const heroCarouselModel = t.exact(
  t.interface({
    slots: t.array(t.union([heroModel, heroHalfModel])),
    blockType: t.literal('HeroCarousel'),
    delaySeconds: t.number,
  }),
  'HeroCarousel'
);

export type HeroCarouselModel = t.TypeOf<typeof heroCarouselModel>;
