import * as t from 'io-ts';
import { contributorsModel } from 'models/contentful/contributors/types';
import { dashHudsonUGCModel } from 'models/contentful/dashHudsonUGC/types';
import { grapheneHcRecommendationsCarouselModel } from 'models/contentful/grapheneHcRecommendationsCarousel/types';
import { grapheneHcRecommendationsGridModel } from 'models/contentful/grapheneHcRecommendationsGrid/types';
import { headerModel } from 'models/contentful/header/types';
import { heroModel } from 'models/contentful/hero/types';
import { heroCarouselModel } from 'models/contentful/heroCarousel/types';
import { heroHalfModel } from 'models/contentful/heroHalf/types';
import { outfitModel } from 'models/contentful/outfit/types';
import { pictureModel } from 'models/contentful/picture/types';
import { productGridModel } from 'models/contentful/productGrid/types';
import { productMosaicModel } from 'models/contentful/productMosaic/types';
import { productRailModel } from 'models/contentful/productRail/types';
import { richTextModel } from 'models/contentful/richText/types';
import { imageModel } from 'models/contentful/shared/types';
import { sizeGuideTabsModel } from 'models/contentful/sizeGuideTabs/types';
import { splitHeroModel } from 'models/contentful/splitHero/types';
import { storiesModel } from 'models/contentful/stories/types';
import { storiesOverlayModel } from 'models/contentful/storiesOverlay/types';
import { subscribeModel } from 'models/contentful/subscribe/types';
import { twoColumnsListingModel } from 'models/contentful/twoColumnsListing/types';

export const HOMEPAGE_SLUG = 'HOMEPAGE';
export const EMPTYCART_SLUG = 'EMPTYCART';
export const NOTFOUND_SLUG = 'NOTFOUND';
export const NORESULTS_SLUG = 'NORESULTS';

export const contentPageSlot = t.union(
  [
    productRailModel,
    productMosaicModel,
    twoColumnsListingModel,
    richTextModel,
    subscribeModel,
    productGridModel,
    pictureModel,
    heroCarouselModel,
    heroModel,
    heroHalfModel,
    splitHeroModel,
    storiesModel,
    storiesOverlayModel,
    outfitModel,
    contributorsModel,
    headerModel,
    sizeGuideTabsModel,
    dashHudsonUGCModel,
    grapheneHcRecommendationsCarouselModel,
    grapheneHcRecommendationsGridModel,
  ],
  'Content Page Slot'
);

export const carouselSlot = t.union(
  [heroModel, heroHalfModel],
  'Carousel Slot'
);

export const contentPageModel = t.exact(
  t.interface({
    header: t.union([headerModel, t.null]),
    browserTitle: t.string,
    metaTitle: t.string,
    metaDescription: t.string,
    metaImage: t.union([imageModel, t.null]),
    displayMode: t.keyof({ block: null, overlay: null }),
    darkMode: t.boolean,
    slots: t.array(contentPageSlot),
  }),
  'Content Page'
);

export type CarouselSlot = t.TypeOf<typeof carouselSlot>;
export type ContentPageSlot = t.TypeOf<typeof contentPageSlot>;
export type ContentPageModel = t.TypeOf<typeof contentPageModel>;
