import Gap from 'components/Gap';
import { Grid } from 'components/Grid';
import InlineStoryColumn from 'components/InlineStoryColumn';
import Media from 'components/Media';
import ProductCard from 'components/ProductCard';
import ProductRail from 'components/ProductRail';
import Story from 'components/Story';
import { MediaModel } from 'models/contentful/media/types';
import { ProductMosaicModel } from 'models/contentful/productMosaic/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const ProductMosaicGrid = styled(Grid)`
  row-gap: 48px;

  > a {
    grid-column-end: span 3;
  }
`;

const ProductMosaicCard = styled(ProductCard)(
  ({ theme: { bp } }) => css`
    display: none;

    ${bp.l} {
      display: block;
    }
  `
);

const ProductRailContainer = styled.div(
  ({ theme: { bp } }) => css`
    ${bp.l} {
      display: none;
    }
  `
);

const ProductMosaic: FC<Omit<ProductMosaicModel, 'blockType'>> = ({
  displayMode,
  mobileDisplayMode,
  media,
  storySource,
  products,
}) => (
  <>
    {mobileDisplayMode === 'Reverse' && (
      <>
        <ProductRailContainer>
          <ProductRail title="" products={products} />
        </ProductRailContainer>
        <Gap size={{ xs: 32, l: 0 }} />
      </>
    )}
    <ProductMosaicGrid columns={{ xs: 6, l: 12 }}>
      <InlineStoryColumn
        starts={{ xs: 1, l: displayMode === 'Reverse' ? 7 : 1 }}
        ends={{ xs: 7, l: displayMode === 'Reverse' ? 13 : 7 }}
        rowStarts={1}
        rowSpans={2}
      >
        {storySource ? (
          <Story vwRelativeWidth={{ xs: 1, l: 0.5 }} {...storySource} />
        ) : (
          <Media
            vwRelativeWidth={{ xs: 1, l: 0.5 }}
            {...(media as MediaModel)}
          />
        )}
      </InlineStoryColumn>
      {products.map((product, index) => (
        <ProductMosaicCard
          {...product}
          index={index}
          key={product.key + index}
        />
      ))}
    </ProductMosaicGrid>

    {mobileDisplayMode === 'Standard' && (
      <>
        <Gap size={{ xs: 32, l: 0 }} />
        <ProductRailContainer>
          <ProductRail title="" products={products} />
        </ProductRailContainer>
      </>
    )}
  </>
);

export default ProductMosaic;
