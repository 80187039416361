import * as t from 'io-ts';
import { storyColumnWidth } from 'models/contentful/stories/types';
import { storySourceModel } from 'models/contentful/storySource/types';

export const storiesOverlayModel = t.exact(
  t.interface({
    storySource: t.array(storySourceModel),
    columnWidth: storyColumnWidth,
    blockType: t.literal('StoriesOverlay'),
  }),
  'StoriesOverlay'
);

export type StoriesOverlayModel = t.TypeOf<typeof storiesOverlayModel>;
