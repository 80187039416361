import CtaBlockLink from 'components/CtaBlockLink';
import Gap from 'components/Gap';
import { Column, Grid as GridComponent } from 'components/Grid';
import { Spans } from 'components/Grid/Grid';
import Header from 'components/Header';
import Media from 'components/Media';
import { triggerSelectContent } from 'lib/gtag';
import toResponsiveValue, { ResponsiveValue } from 'lib/toResponsiveValue';
import { LinkModel } from 'models/contentful/link/types';
import { StoriesModel } from 'models/contentful/stories/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const colSpans: ResponsiveValue<Spans>[] = [
  { xs: 6, l: 12 },
  { xs: 3, l: 6 },
  { xs: 6, m: 2, l: 4 },
  { xs: 3, l: 6, xl: 3 },
];

const maxNumContentColumns: ResponsiveValue<Spans>[] = [
  { xs: 1 },
  { xs: 2 },
  { xs: 1, m: 3 },
  { xs: 2, xl: 4 },
];

const relativeWidths: ResponsiveValue<number>[] = [
  { xs: 1 },
  { xs: 0.5 },
  { xs: 1, m: 0.333333 },
  { xs: 0.5, xl: 0.25 },
];

const StoryTitle = styled.div``;

const Grid = styled(GridComponent)<{ contentColumns?: ResponsiveValue<Spans> }>(
  ({ contentColumns: f = 1, theme: { bp } }) => {
    const contentColumns = toResponsiveValue(f);

    return css`
      margin-bottom: -32px;
      ${StoryTitle} {
        margin-inline-end: 15px;
      }

      ${bp.xs} {
        > :nth-child(${contentColumns.xs}n + 1) {
          ${StoryTitle} {
            margin-inline-start: 15px;
          }
        }
        margin-bottom: -48px;
      }

      ${bp.s} {
        > :nth-child(${contentColumns.m}n + 1) {
          ${StoryTitle} {
            margin-inline-start: 18px;
          }
        }
      }

      ${bp.l} {
        > :nth-child(${contentColumns.l}n + 1) {
          ${StoryTitle} {
            margin-inline-start: 38px;
          }
        }
        margin-bottom: -48px;
      }

      ${bp.xl} {
        > :nth-child(${contentColumns.xl}n + 1) {
          ${StoryTitle} {
            margin-inline-start: 48px;
          }
        }
      }
    `;
  }
);

const Stories: FC<Omit<StoriesModel, 'blockType'>> = ({
  storySource,
  columnWidth,
}) => {
  const contentColumns = maxNumContentColumns[columnWidth - 1];
  const spans = colSpans[columnWidth - 1];
  const vwRelativeWidth = relativeWidths[columnWidth - 1];

  const handleOnClick = (title: string, action: LinkModel) =>
    triggerSelectContent('Story CTA - media', title, action, global.window);

  return (
    <>
      <Grid
        spacing="none"
        inlineMargin={0}
        contentColumns={contentColumns}
        data-testid="Stories"
        columns={{ xs: 6, l: 12 }}
      >
        {storySource.map((story, index) => {
          const { link, media, header } = story;

          return (
            <Column key={index} spans={spans}>
              <CtaBlockLink
                url={link}
                onClickCallback={() =>
                  handleOnClick(media.xs?.image.title || header.title, {
                    link,
                    title: header.title,
                  })
                }
              >
                <Media vwRelativeWidth={vwRelativeWidth} {...media} />
              </CtaBlockLink>
              <StoryTitle>
                <Gap size={16} />
                <Header
                  inline={true}
                  headerLevel="h2"
                  title={header.title}
                  titleLink={link}
                  description={header.description}
                  actions={header.actions}
                />
                <Gap size={{ xs: 32, l: 48 }} />
              </StoryTitle>
            </Column>
          );
        })}
      </Grid>
    </>
  );
};

export default Stories;
