import { Grid } from 'components/Grid';
import Hero from 'components/Hero';
import HeroCarousel from 'components/HeroCarousel';
import { getColor } from 'components/Stories/helpers';
import { Title } from 'components/Typography';
import { ContentPageModel } from 'models/contentPages/types';
import { SplitHeroModel } from 'models/contentful/splitHero/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(() => {
  const heroHeight = `calc(100vh - 80px)`;

  return css`
    position: relative;

    height: ${heroHeight};
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: 50% 20%;
    }
  `;
});

const Header = styled(Title)`
  position: absolute;
  width: 100%;
  top: 15%;
  z-index: 1;
`;

const MobileView = styled.div(
  ({ theme: { bp } }) => css`
    ${bp.l} {
      display: none;
    }
  `
);

const DesktopView = styled.div(
  ({ theme: { bp } }) => css`
    display: none;

    ${bp.l} {
      display: initial;
    }
  `
);

const SplitHero: FC<
  Omit<SplitHeroModel, 'blockType'> & Pick<ContentPageModel, 'displayMode'>
> = props => {
  return (
    <Wrapper>
      <Header
        variant="1"
        as="p"
        size="M"
        textColor={getColor({
          textColor: props.slots[0].storySource.textColor,
        })}
        textAlign="center"
      >
        {props.title}
      </Header>
      <MobileView>
        <HeroCarousel {...props} />
      </MobileView>
      <DesktopView>
        <Grid spacing="none" inlineMargin={0} columns={2}>
          <Hero {...props.slots[0]} />
          <Hero {...props.slots[1]} />
        </Grid>
      </DesktopView>
    </Wrapper>
  );
};

export default SplitHero;
