import { Color } from 'components/Typography/types';
import { OptionallyResponsiveValue } from 'lib/toResponsiveValue';
import { StorySourceModel } from 'models/contentful/storySource/types';

type GetColors = (
  args: Pick<StorySourceModel, 'textColor'>
) => OptionallyResponsiveValue<Color> | undefined;

export const getColor: GetColors = ({ textColor }) => {
  if (textColor === null) return undefined;
  if (typeof textColor !== 'object') return textColor;
  return {
    xs: textColor.xs || undefined,
    s: textColor.s || undefined,
    m: textColor.m || undefined,
    l: textColor.l || undefined,
    xl: textColor.xl || undefined,
    xxl: textColor.xxl || undefined,
  };
};
