import { RefObject, UIEvent } from 'react';

export type ScrollPosition = 'beginning' | 'middle' | 'end';

const getLeft = (els: Element[], left: number) => {
  const lefts = (els as HTMLLIElement[])
    .map(item => item.offsetLeft)
    .filter(itemLeft => itemLeft < left);

  if (lefts.length <= 1) {
    return 0;
  }

  return lefts[lefts.length - 1];
};

const getRight = (els: Element[], right: number, maxScroll: number) => {
  const rights = (els as HTMLLIElement[])
    .map(el => el.offsetLeft + el.clientWidth)
    .filter(itemRight => itemRight > right);

  if (rights.length <= 1) {
    return maxScroll;
  }

  return rights[rights.length - 1];
};

export const scrollLeft = (scroller: RefObject<HTMLUListElement>) => () => {
  if (!scroller.current) {
    return;
  }

  const left = scroller.current.scrollLeft;

  const xScroll = getLeft(Array.from(scroller.current.children), left);

  scroller.current.scrollTo({
    left: xScroll,
    behavior: 'smooth',
  });
};

export const scrollRight = (scroller: RefObject<HTMLUListElement>) => () => {
  if (!scroller.current) {
    return;
  }

  const right = scroller.current.scrollLeft + scroller.current.clientWidth;

  const xScroll = getRight(
    Array.from(scroller.current.children).reverse(),
    right,
    scroller.current.scrollWidth
  );

  scroller.current.scrollTo({
    left: xScroll - scroller.current.clientWidth,
    behavior: 'smooth',
  });
};

export const markScrollPositionCallback =
  (
    previousScrollPosition: ScrollPosition,
    setScrollPosition: (position: ScrollPosition) => void
  ) =>
  (e: UIEvent<HTMLUListElement>) => {
    const el = e.target as HTMLDivElement;

    let scrollPosition: ScrollPosition;

    if (el.scrollLeft <= 0) {
      scrollPosition = 'beginning';
    } else if (el.scrollLeft >= Math.abs(el.clientWidth - el.scrollWidth)) {
      scrollPosition = 'end';
    } else {
      scrollPosition = 'middle';
    }

    if (scrollPosition === previousScrollPosition) {
      return;
    }

    setScrollPosition(scrollPosition);
  };
