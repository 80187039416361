import * as t from 'io-ts';
import { mediaModel } from 'models/contentful/media/types';
import { document } from 'models/contentful/richText/types';

export const pictureModel = t.exact(
  t.interface({
    caption: t.union([document, t.null]),
    size: t.keyof({ Small: null, Medium: null, Large: null, Full: null }),
    picture: mediaModel,
    link: t.string,
    blockType: t.literal('Picture'),
  }),
  'Picture'
);

export type PictureModel = t.TypeOf<typeof pictureModel>;
