import ProductRail from 'components/ProductRail';
import useRecommendations from 'hooks/useRecommendations';
import { GrapheneHcRecommendationsCarouselModel } from 'models/contentful/grapheneHcRecommendationsCarousel/types';
import { FC } from 'react';

const RecommendationsCarousel: FC<
  Pick<GrapheneHcRecommendationsCarouselModel, 'pageElementId'>
> = ({ pageElementId: element }) => {
  const { loading, recommendations } = useRecommendations({ element });

  return (
    <ProductRail
      loading={loading}
      products={recommendations.map(product => ({
        ...product.listing,
        swatches: {},
      }))}
    />
  );
};

export default RecommendationsCarousel;
